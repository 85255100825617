import { Platform } from "react-native";
import Constants from 'expo-constants';
import { Revision } from '../constants/Version';

export function getUserMeta(expoToken: string) {
    return {
        ini: Constants.installationId,
        deviceId: Constants.deviceId,
        deviceName: Constants.deviceName,
        deviceYearClass: Constants.deviceYearClass,
        platform: Platform.OS,
        platformVersion: Platform.Version,
        expoRuntimeVersion: Constants.expoRuntimeVersion,
        expoVersion: Constants.expoVersion,
        sessionId: Constants.sessionId,
        nativeAppVersion: Constants.nativeAppVersion,
        nativeBuildVersion: Constants.nativeBuildVersion,
        revision: Revision,
        expoToken,
    };
}

export const BaseUrl = 'https://elearning.mla.com.au';

export const Endpoints = {
    UserInfo: `${BaseUrl}/wp-json/mla/v1/sso/info`,
    Login: `${BaseUrl}/wp-json/mla/v1/sso/app/login`,
    Notifications: `${BaseUrl}/wp-json/mla/v1/sso/app/notifications`
};

// export const Auth0 = {
//     ClientId: "",
//     AuthEndpoint: "https://mymla.au.auth0.com/authorize",
//     TokenEndpoint: "https://mymla.au.auth0.com/token"
// }