import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Image, StyleSheet, Text, View, Dimensions, Platform, ScrollView } from 'react-native';
// import { ScrollView } from 'react-native-gesture-handler';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import StackScreen from './StackScreen';
import * as AuthSession from 'expo-auth-session';
import * as Linking from 'expo-linking';
import { getQueryStringParameters, appendToUrl } from '../common';
import { useAuthRequest, ResponseType } from 'expo-auth-session';
// import qs from 'qs';
import MLALoginButton from '../components/MLALoginButton';
import MLAButton from '../components/MLAButton';
import useAppAuth from '../hooks/useAppAuth';
import Colors from '../constants/Colors';
import MediaQuery from "react-native-web-responsive";
import MLALogo from '../components/MLALogo';
import WebPackageService, { EWebPackageType } from '../services/WebPackageService';
import { Card, Title, Paragraph, Badge, Button, TouchableRipple, List, Switch, FAB } from 'react-native-paper';
import Constants from 'expo-constants';
import { Revision } from '../constants/Version';
import { StatusBar } from 'expo-status-bar';
import { isDeveloperMode } from '../services/UserService';

// import Logo from '../assets/images/mla.svg';

const useProxy = false;//Platform.select({ web: false, default: true });
// const redirectUri = 'https://lms.morganruraltech.com.au/index.php?auth0=1'; //'https://lms.morganruraltech.com.au/wp-json/mla/v1/sso/login'; // AuthSession.makeRedirectUri({ useProxy });
//const redirectUri = 'https://lms.morganruraltech.com.au/wp-json/mla/v1/sso/login/'; // AuthSession.makeRedirectUri({ useProxy });
const redirectUri = AuthSession.makeRedirectUri({ useProxy });

const Spacer = (props?: any) => <View style={styles.spacer} {...props} />
const CurrentYear = (new Date()).getFullYear();
const ShippedYear = 2020;

let scrollTimer = 0;

export default function HomeScreen() {
  //return <StackScreen Component={() => {
  const navigation = Platform.OS == 'web' ? null : useNavigation();
  const [refresh, setRefresh] = React.useState(0);
  const { signOutAsync, isSignedIn, isLoading, userData } = useAppAuth();//() => {
  //   // console.log('HomeScreen', isSignedIn, isLoading);
  //   //setRefresh(refresh + 1);
  // });

  // const [name, setName] = React.useState(null);

  // const [request, result, promptAsync] = AuthSession.useAuthRequest(
  //   {
  //     redirectUri,
  //     clientId: auth0ClientId,
  //     // id_token will return a JWT token
  //     responseType: AuthSession.ResponseType.Token,
  //     // retrieve the user's profile
  //     scopes: ["openid", "profile"],
  //     extraParams: {
  //       // ideally, this will be a random value
  //       nonce: "nonce",
  //     },
  //   },
  //   {
  //     authorizationEndpoint,
  //     tokenEndpoint,
  //   }
  // );
  // console.log('request', request);

  // console.log('refresh main a', isSignedIn, isLoading);
  // React.useEffect(() => {
  // }, [isSignedIn]);

  if (navigation) {
    React.useEffect(() => {
      navigation.setOptions({
        headerTitle: "",
        title: 'eTools | Meat & Livestock Australia',
        // headerShown: false, 
        header: () => <View></View>,
      });
    }, [])
  } else {
    document.title = 'eTools | Meat & Livestock Australia';
  }

  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;

  const isMobile = windowWidth <= 768;
  const isTablet = windowWidth <= 1000 && windowWidth > 768;
  const isDesktop = windowWidth > 1000;

  let cardPercentage = '100%';
  let containerWidth = '100%';

  if (windowWidth <= 768) {
    cardPercentage = '100%';
    containerWidth = '100%';
  }
  else if (windowWidth <= 1000) {
    cardPercentage = '45%'
  }
  else {
    cardPercentage = '30%'
    containerWidth = '96%';
  }

  let lastResize = 0;
  function onResize() {
    let now = + new Date();
    lastResize = now;
    window.setTimeout((c: number) => {
      if (c == lastResize) {
        setRefresh(refresh + 1);
      }
    }, 100, now);
  }

  window.onresize = onResize;
  const [isSwitchOn, setIsSwitchOn] = React.useState(false);
  const [showScrollUp, setShowScrollUp] = React.useState(false);

  const onToggleSwitch = () => setIsSwitchOn(!isSwitchOn);
  // const scrollViewRef = React.createRef<ScrollView>();
  const scrollViewRef = React.useRef<ScrollView | null>(null);

  // console.log('showScrollUp', showScrollUp, scrollViewRef);

  const showSignInData = isSignedIn && !isLoading;
  return <View style={styles.viewContainer}>
    <StatusBar backgroundColor="#fff" animated={true} hidden />
    {/* <StatusBar backgroundColor="#007549" /> */}
    <ScrollView style={styles.container} //ref={scrollViewRef}
      ref={scrollViewRef}
      // onScrollEndDrag={(evt) => {
      //   console.log('evt.nativeEvent.contentOffset.y', evt.nativeEvent.contentOffset.y);
      //   setShowScrollUp(evt.nativeEvent.contentOffset.y != 0);
      // }}
      // onMomentumScrollEnd={(evt) => {
      //   console.log('evt.nativeEvent.contentOffset.y', evt.nativeEvent.contentOffset.y);
      //   setShowScrollUp(evt.nativeEvent.contentOffset.y != 0);
      // }}
      scrollEventThrottle={1}
      onScroll={(evt) => {
        const offset = evt.nativeEvent.contentOffset.y;
        const now = +new Date();
        scrollTimer = now;
        setTimeout((n) => {
          if (n == scrollTimer) {
            // console.log('evt.nativeEvent.contentOffset.y', offset);
            setShowScrollUp(offset != 0);
          }
        }, 50, now);
      }}
    >
      {/* <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}> */}

      {/* <MediaQuery minWidth={1224}>
          <View style={styles.headerDesktop}>
            <View>
              <Image
                source={require('../assets/images/mlas.png')}
                style={styles.headerLogoDesktop}
              />
              {/* <MLALogo style={styles.headerLogoDesktop} /> * /}
            </View>

            <View style={styles.headerTextContainerDesktop}>
              <Text style={styles.headerTextDesktop}>
                Tools &amp; Calculators
            </Text>
            </View>
          </View>
        </MediaQuery>

        <MediaQuery maxWidth={1224}>
          <View style={styles.headerMobile}>
            <View>
              <Image
                source={require('../assets/images/mlas.png')}
                style={styles.headerLogoMobile}
              />
              {/* <MLALogo style={styles.headerLogoDesktop} /> * /}
            </View>

            <View style={styles.headerTextContainerMobile}>
              <Text style={styles.headerTextMobile}>
                Tools &amp; Calculators
            </Text>
            </View>
          </View>
        </MediaQuery> */}

      <View style={isMobile ? styles.headerMobile : styles.headerDesktop}>
        <View>
          <Image
            source={require('../assets/images/mla-logo-2020.png')}
            style={isMobile ? styles.headerLogoMobile : styles.headerLogoDesktop}
          />
          {/* <MLALogo style={styles.headerLogoDesktop} /> */}
        </View>

        <View style={isMobile ? styles.headerTextContainerMobile : styles.headerTextContainerDesktop}>
          <Text style={isMobile ? styles.headerTextMobile : styles.headerTextDesktop}>
            Tools &amp; calculators
        </Text>
        </View>
      </View>
        {/* <Button icon="menu">Test</Button> */}

      <View style={styles.invertedBg}>
        <Text style={styles.invertedFg}>MLA provides a range of tools and calculators to assist red meat producers in making decisions in their businesses.</Text>

        <View style={styles.loginContainer}>
          <MLALoginButton />
          <Spacer />
          {/* {showSignInData && <MLAButton title="Sign out" onPress={() => signOutAsync()} />} */}
          {/* {showSignInData && <Spacer style={{ paddingTop: 25 }} />} */}
          {showSignInData && userData && userData.user?.site_locations && <View>
            {/* <Text style={{ fontWeight: 'bold', color: Colors.tintColor, fontSize: 20 }}>Your Regions</Text>
          <Spacer />
          {userData.user.site_locations.map((r, ix) => <Text key={ix} style={{ fontSize: 12 }}>{r.name}</Text>)} */}

            {/* <View style={{ justifyContent: 'center' }}>
            {userData.user.site_locations.map((r, ix) => <List.Item key={ix}
              title={r.name}
              style={{
                padding: 0
              }}
              left={props => <List.Icon {...props} icon="map-marker" />}
            />)}
          </View> */}
            <View style={{
              justifyContent: 'center',
              alignItems: 'center',
              // width: 200,
            }}>
              {userData.user.site_locations.map((r, ix) => <View key={ix}
                style={{
                  // flex: 1,
                  flexDirection: 'row',
                  alignContent: 'center',
                  // justifyContent: 'center',
                  alignItems: 'center',
                  width: 200,
                  // margin: 'auto',
                }}
              >
                <List.Icon icon="map-marker" />
                <Text>{r.name}</Text>
              </View>)}
            </View>
            {/* <List.Subheader>
            <Text style={{ color: '#043321' }}
              onPress={() => Linking.openURL('https://elearning.mla.com.au/#my-locations-section')}>
              Looking outdated? Click here to edit your locations.
            </Text>
          </List.Subheader> */}
            <Text style={{ color: '#043321' }}
              onPress={() => Linking.openURL('https://elearning.mla.com.au/#my-locations-section')}>
              Looking outdated? Click here to edit your locations.
            </Text>
          </View>}
        </View>
      </View>
      {/* <View>
      <Text>W: {windowWidth}</Text>
    </View> */}


      {/* <View style={{
      flex: 1,
    }}>
      <Text>Open in browser</Text>
      <Switch value={isSwitchOn} onValueChange={onToggleSwitch} />
    </View> */}

      <View style={{
        alignItems: 'center'
      }}>
        <View style={{
          display: 'flex',
          flexDirection: 'row',
          width: containerWidth,
          margin: 'auto',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
          marginBottom: 50
        }}>
          {WebPackageService.getPackages().map((p, ix) => {
            // const comingSoon = !p.onPress;
            // const comingSoon = p.comingSoon ? isDeveloperMode(userData, '') : p.comingSoon;
            let comingSoon = p.comingSoon;
            let underMaintenace = p.underMaintenance;
            let clickable = !p.comingSoon && !p.underMaintenance;

            if (comingSoon || underMaintenace) {
              if (Constants.debugMode) {
                if (isDeveloperMode(userData?.user, 'dev')) {
                  clickable = true;
                }
              } else {
                if (isDeveloperMode(userData?.user, 'live')) {
                  clickable = true;
                }
              }
            }

            return <Card key={`${ix}_${p.label}`}
              style={{
                width: cardPercentage,
                borderWidth: 1,
                borderColor: '#ccc',
                marginTop: 50,
                backgroundColor: (comingSoon || underMaintenace) ? '#eee' : '#fff',

                ...Platform.select({
                  web: {
                    cursor: (comingSoon || underMaintenace) ? 'not-allowed' : 'pointer',
                  },
                  default: {},
                }),
              }}>

              <TouchableRipple
                disabled={!clickable}
                onPress={() => {
                  // console.log('clickable', clickable);
                  if (typeof p.onPress == 'string' && clickable) {
                    switch (Platform.OS) {
                      case 'android':
                      case 'ios':
                        if (navigation) navigation.navigate('WebView', {
                          uri: appendToUrl(p.onPress, { platform: Platform.OS }),
                          title: p.label,
                        })
                        break;
                      default:
                        Linking.openURL(appendToUrl(p.onPress, { linking: `1` }));
                        break;
                    }
                  }
                }}
                style={{ flex: 1, zIndex: 1000, position: 'relative' }}
              >
                <View
                  style={{ flex: 1 }}>
                  <View style={{
                    borderTopRightRadius: 50,
                    borderBottomRightRadius: 50,
                    overflow: 'hidden',

                    alignSelf: 'flex-start',
                    top: -15,
                    position: 'relative',
                    left: -1, // -1 for border
                  }}>
                    <Badge style={{
                      paddingRight: 20,
                      paddingLeft: 20,
                      position: 'relative',

                      backgroundColor: (comingSoon || underMaintenace) ? '#7ab39c' : '#007549',
                      color: '#fff',

                      borderRadius: 0,
                    }} visible={true} size={30}>
                      {p.type == EWebPackageType.Calculator ? 'CALCULATOR' :
                        p.type == EWebPackageType.Tool ? 'TOOL'
                          : ''}
                    </Badge>
                  </View>
                  {(comingSoon || underMaintenace) && <View style={{
                    position: 'absolute',
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                  }}>
                    <Text style={{
                      // backgroundColor: '#007549',
                      backgroundColor: '#7ab39c',
                      width: '100%',
                      padding: 10,
                      textAlign: 'center',
                      color: '#fff',
                      fontSize: 20,
                    }}>
                      {comingSoon && 'Coming soon'}
                      {underMaintenace && 'Under maintenance'}
                      {clickable && <>
                        {' '}
                        <Text style={{
                          color: '#f00',
                        }}>[dev]</Text>
                      </>}
                    </Text>
                  </View>}
                  <Card.Cover source={p.thumbnail} />
                  <Card.Title title={p.label} />
                  <Card.Content
                    style={{ flex: 1 }}>
                    <Paragraph>{p.description}</Paragraph>
                  </Card.Content>
                  <Card.Actions style={{ justifyContent: 'flex-end' }}>
                    {(comingSoon || underMaintenace) && <Button>View</Button>}
                  </Card.Actions>
                </View>
              </TouchableRipple>
            </Card>
          })}
        </View>
      </View>

      <View style={styles.invertedBg}>
        <Text style={[styles.invertedFg, styles.footer]}>
          &copy; {ShippedYear}{ShippedYear != CurrentYear && `-${CurrentYear}`} Meat &amp; Livestock Australia Limited
          ABN 39 081 678 364
        </Text>
      </View>
      <View style={styles.invertedBg}>
        <Text style={{
          color: '#043a25',
          textAlign: 'right',
          fontSize: 10,
          paddingRight: 5,
          paddingBottom: 5,
        }}>
          v{[Constants.nativeAppVersion, Constants.nativeBuildVersion, Revision].filter(x => x != null && x.toString().length > 0).join('/')}
        </Text>
      </View>


      {/* <View style={styles.welcomeContainer}>
          <Image
            source={require('../assets/images/logo.png')}
            style={styles.welcomeImage}
          />
        </View>

        <View style={styles.homeContainer}>
          <Text style={styles.homeText}>
            Welcome to the MLA Tools &amp; Calculators app.
          </Text>
        </View>

        <View style={styles.loginContainer}>
          <MLALoginButton />
          <Spacer />
          {showSignInData && <MLAButton title="Sign out" onPress={() => signOutAsync()} />}
          {showSignInData && <Spacer style={{ paddingTop: 25 }} />}
          {showSignInData && userData && userData.user?.site_locations && <View>
            <Text style={{ fontWeight: 'bold', color: Colors.tintColor, fontSize: 20 }}>Your Regions</Text>
            {/* <Text>Data may be shown that is only relevant to your regions</Text> * /}
            <Spacer />
            {userData.user.site_locations.map((r, ix) => <Text key={ix} style={{ fontSize: 12 }}>{r.name}</Text>)}
          </View>}
        </View> */}
      {/* </ScrollView> */}
    </ScrollView>

    {showScrollUp && <FAB
      style={styles.fab}
      // small
      // label="Home"
      color={`#fff`}
      icon="arrow-up"
      onPress={() => {
        if (scrollViewRef && scrollViewRef.current) {
          scrollViewRef.current.scrollTo({ x: 0, y: 0 });
        }
      }}
    />}
  </View>
  //}} />
}

const styles = StyleSheet.create({
  fab: {
    position: 'absolute',
    // marginBottom: 20,
    // marginLeft: 16,
    // left: 0,
    // bottom: 0,
    marginBottom: 8 * 2,
    marginRight: 8 * 2,
    right: 0,
    bottom: 0,
    backgroundColor: '#007549',
    // backgroundColor: '#007549'
  },

  viewContainer: {
    flex: 1,
  },

  headerMobile: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingTop: 40,
    paddingBottom: 40,
  },
  headerLogoMobile: {
    width: 175,
    height: 100,
    resizeMode: 'contain'
  },
  headerTextMobile: {
    color: '#007549',
    fontWeight: '700',
    fontSize: 30,
  },
  headerTextContainerMobile: {
    // justifyContent: 'center',
    // alignItems: 'center',
  },

  headerDesktop: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 1000,
    paddingTop: 40,
    paddingBottom: 40,
  },
  headerLogoDesktop: {
    width: 175,
    height: 100,
    resizeMode: 'contain'
  },
  headerTextDesktop: {
    color: '#007549',
    fontWeight: '700',
    fontSize: 50,
  },
  headerTextContainerDesktop: {
    justifyContent: 'center',
    alignItems: 'center',
  },

  invertedBg: {
    backgroundColor: '#007549',
  },
  invertedFg: {
    color: '#fff',
    textAlign: 'center',
    fontWeight: '400',
    fontSize: 20,
    // padding: '12px',
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 10,
    paddingRight: 10,
  },

  footer: {
    fontSize: 15,
  },

  container: {
    flex: 1,
    // alignContent: 'flex-start'
    alignContent: 'center',
    //alignItems: 'center',
    //justifyContent: 'center'
    backgroundColor: '#f3f1ef',
  },
  loginContainer: {
    flex: 1,
    paddingTop: 0,
    paddingBottom: 10,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  // contentContainer: {
  //   // paddingTop: 30,
  //   // justifyContent: 'center',
  //   // alignContent: 'center',
  //   // alignItems: 'center',
  //   // flex: .8
  // },
  // welcomeContainer: {
  //   alignItems: 'center'
  // },
  // welcomeImage: {
  //   width: 175,
  //   height: 100,
  //   resizeMode: 'contain'
  // },
  // homeContainer: {
  //   alignItems: 'center',
  //   marginHorizontal: 50,
  //   flexShrink: 0
  // },
  // homeText: {
  //   fontSize: 17,
  //   color: '#000',
  //   lineHeight: 24,
  //   textAlign: 'center',
  // },
  spacer: {
    paddingTop: 5,
  },
});
