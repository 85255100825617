import WebViewMessage from "./message";
import { Alert, AlertStatic, AlertButton, AlertOptions } from "react-native";
import * as AuthSession from 'expo-auth-session';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import useAppAuth from "../hooks/useAppAuth";

import { signOutAsync, signInAsync, getUserData, UserData, Notifications, NotificationTypes } from '../services/UserService';
import NotificationService from "../services/NotificationService";
import { WebBrowserOpenOptions } from "expo-web-browser";

interface OpenBrowserMessageData {
    url: string;
    browserParams?: WebBrowserOpenOptions;
}

export default class OpenBrowserMessage extends WebViewMessage<OpenBrowserMessageData> {
    public async onReceivedMessage(data: OpenBrowserMessageData) {
        let result = await WebBrowser.openBrowserAsync(data.url, data.browserParams);
        this.reply({ result });
    }
}