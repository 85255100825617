import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    Root: {
      path: 'root',
      screens: {
        Hub: 'hub',
        Tools: 'tools',
        Calculators: 'calculators',
        WebView: 'webview'
      },
    },
  },
};
