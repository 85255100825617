import WebViewMessage from "./message";
import { Alert, AlertStatic, AlertButton, AlertOptions } from "react-native";
import * as AuthSession from 'expo-auth-session';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import useAppAuth from "../hooks/useAppAuth";

import { signOutAsync, signInAsync, getUserData, UserData, Notifications, NotificationTypes } from '../services/UserService';
import NotificationService from "../services/NotificationService";

interface AuthMessageData {

}

export default class AuthMessage extends WebViewMessage<AuthMessageData> {
    public async onReceivedMessage(data: AuthMessageData) {
        // alert(data.message);
        // let returnUrl = AuthSession.getRedirectUrl('redirect');
        // // let returnUrl = Linking.makeUrl();
        // let returnUrl = AuthSession.makeRedirectUri({ useProxy: false });

        // console.log('native auth?', returnUrl);

        // let resp = await AuthSession.startAsync({
        //     //authUrl: 'https://lms.morganruraltech.com.au/wp-json/mla/v1/sso/login'
        //     authUrl: `https://lms.morganruraltech.com.au/wp-login.php?redirect_to=${encodeURIComponent('/wp-json/mla/v1/sso/app')}`,
        //     returnUrl,

        // });
        // let resp = await WebBrowser.openAuthSessionAsync(`https://lms.morganruraltech.com.au/wp-login.php?redirect_to=${encodeURIComponent('/wp-json/mla/v1/sso/app')}`, returnUrl);
        // console.log('openBrowserAsync: ', resp);

        const token = NotificationService.getToken();
        //   const { isSignedIn, isLoading, signInAsync, userData } = useAppAuth();
        const resp = await signInAsync(token);

        this.reply(resp);
    }
}