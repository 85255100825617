import NotificationService, { Notification } from "../services/NotificationService";
import { useEffect } from "react";

export default function useNotifications(onReceivedNotification: (notification: Notification) => void) {
    useEffect(() => {
        const handle = NotificationService.addListener(onReceivedNotification);
        return function () {
            handle.remove();
        };
    }, []);
    return {};
};