import WebViewMessage from "./message";
import XLSX from 'xlsx';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';

interface ExcelData {
    data: unknown[];
    workbook: string;
    filename: string;
    dialogTitle: string;
}

export default class ExcelMessage extends WebViewMessage<ExcelData> {
    public async onReceivedMessage(data: ExcelData) {
        try {
            // console.log('excel', data);
            if (Sharing.isAvailableAsync()) {
                const ws = XLSX.utils.json_to_sheet(data.data);
                if (ws) {
                    const uri = FileSystem.cacheDirectory + data.filename + '.xlsx';
                    const wb = XLSX.utils.book_new();

                    XLSX.utils.book_append_sheet(wb, ws, data.workbook);

                    const wbout = XLSX.write(wb, {
                        type: 'base64',
                        bookType: "xlsx"
                    });
                    
                    await FileSystem.writeAsStringAsync(uri, wbout, {
                        encoding: FileSystem.EncodingType.Base64
                    });

                    const shareResponse = await Sharing.shareAsync(uri, {
                        dialogTitle: data.dialogTitle,
                        mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        UTI: 'com.microsoft.excel.xlsx'
                    });

                    this.reply({ status: true, shareResponse });
                } else {
                    this.reply({ status: false });
                }
            } else {
                console.log('Sharing is not supported');
                this.reply({ status: false, error: 'not supported' });
            }
        } catch (ex) {
            console.error(ex);
            this.reply({ status: false, error: true });
        }
    }
}