
export const getQueryStringParameters = <TModel = any>(search?: string) => {
	if (search == undefined) {
		search = window.location.search;
	}
	const hashes = search.slice(search.indexOf("?") + 1).split("&");
	return hashes.reduce((params, hash) => {
		const split = hash.indexOf("=");

		if (split < 0) {
			return Object.assign(params, {
				[hash]: null
			});
		}

		const key = hash.slice(0, split);
		const val = hash.slice(split + 1);

		return Object.assign(params, { [key]: decodeURIComponent(val) });
	}, {}) as TModel;
};

export function appendToUrl(baseUrl: string, ...args: string[] | { [key: string]: string }[]) {
	let url = baseUrl;
	for (let arg of args) {
		if (typeof arg == 'string') {
			url += url.indexOf('?') > -1 ? '&' : '?';
			url += arg;
		} else {
			for (let key of Object.keys(arg)) {
				url += url.indexOf('?') > -1 ? '&' : '?';
				url += `${key}=${arg[key]}`;
			}
		}
	}
	return url;
}