import AlertMessage from "./alert";
import WebViewMessage from "./message";
// import PromptMessage from "./prompt";
import WebView, { WebViewMessageEvent } from "react-native-webview";
import EvalMessage from "./eval";
import NotificationsMessage from "./notifications";
import { Platform } from "react-native";
import AuthMessage from "./auth";
import OpenBrowserMessage from "./browser";
import PrintMessage from "./print";
import ExcelMessage from "./excel";
import LinkingMessage from "./linking";

const Messages: { [key: string]: (webView: WebView | null, reply: (data?: object) => void) => WebViewMessage } = {
    'native-alert': (wv, r) => new AlertMessage(wv, r),
    // 'native-prompt': (wv, r) => new PromptMessage(wv, r),  currently Alert.prompt isnt working in RN...
    'native-eval': (wv, r) => new EvalMessage(wv, r),
    'native-notification-state': (wv, r) => new NotificationsMessage(wv, r),
    'native-auth': (wv, r) => new AuthMessage(wv, r),
    'native-open-browser': (wv, r) => new OpenBrowserMessage(wv, r),
    'native-print': (wv, r) => new PrintMessage(wv, r),
    'native-excel': (wv, r) => new ExcelMessage(wv, r),
    'native-linking': (wv, r) => new LinkingMessage(wv, r),
};

export function processWebViewEvent(webView: WebView | null, event: WebViewMessageEvent) {
    // console.log('event.nativeEvent.data', event.nativeEvent.data);

    try {
        if (event.nativeEvent.data) {
            let msg = JSON.parse(event.nativeEvent.data);
            if (typeof msg == 'object' && 'messageType' in msg && typeof msg.messageType == 'string') {
                let handler = Messages[msg.messageType];
                const reply = (data?: object) => {
                    if ('replyId' in msg) {
                        if (webView) {
                            let args = JSON.stringify(data);
                            // console.log(`Replying to '${msg.replyId}': ${args}`);
                            webView.injectJavaScript(`window.AppData.Messages['${msg.replyId}'](${args});`);
                        } else {
                            // console.log('Failed to reply, no webView instance');
                        }
                    } else {
                        // console.log('Failed to reply, no reply id');
                    }
                }

                if (handler) {
                    let message = handler(webView, reply);
                    if (message) {
                        message.onReceivedMessage(msg);
                    }
                } else {
                    reply({ error: `No handler for '${msg.messageType}'` });
                }
            }
        }
    }
    catch (ex) {
        console.error('[App.WV.onMessage]', ex);
    }
}