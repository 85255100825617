import WebView from "react-native-webview";

export default abstract class WebViewMessage<TData = any> {
    private replyFn?: (data?: object) => void;

    constructor(
        // public type: string,
        public webView: WebView | null,
        reply?: (data?: object) => void
    ) {
        this.replyFn = reply;
    }

    public abstract onReceivedMessage(data?: TData): Promise<void>;

    public reply(data?: object) {
        if (this.replyFn) {
            this.replyFn(data);
        } else {
            // console.error('Bridge failure');
        }
    }
}