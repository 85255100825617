import { Platform, ImageSourcePropType } from 'react-native';
import { Revision } from '../constants/Version';

export const SubVersion = 5;

export enum EWebPackageType {
    Tool,
    Calculator
}

interface WebPackage {
    type: EWebPackageType;
    label: string;
    description: string;
    onPress?: string | (() => void);
    icon: string;
    thumbnail: ImageSourcePropType;
    displayOrder: number;
    comingSoon: boolean;
    underMaintenance: boolean;
}

class WebPackageServiceImpl {
    // public ota_website_example: string | null = null;
    // public feed_demand_calculator: string | null = `http://etools.mla.com.au/fdc/?v=${SubVersion}&platform=${Platform.OS}`;
    // public feedbase_planning_and_budgeting_tool: string | null = `http://etools.mla.com.au/fbrp/?v=${SubVersion}&platform=${Platform.OS}`;
    // public pasture_improvement_calculator: string | null = `http://etools.mla.com.au/pic/?v=${SubVersion}&platform=${Platform.OS}`;
    // public pasture_trial_network: string | null = `https://ptn.morganruraltech.com.au/?v=${SubVersion}&platform=${Platform.OS}`;
    // public phosphorus_tool: string | null = `http://etools.mla.com.au/ptool/?v=${SubVersion}&platform=${Platform.OS}`;
    // public stocking_rate_calculator: string | null = `http://etools.mla.com.au/src/?v=${SubVersion}&platform=${Platform.OS}`;

    private packages: WebPackage[] = [
        // tools
        {
            type: EWebPackageType.Tool,
            icon: "md-build",
            label: "Soil phosphorus tool",
            description: `This tool is intended to assist farmers in determining suitable levels of P-fertilisation of temperate pastures grazed by sheep and beef cattle on acid soils in southern Australia.`,
            onPress: `https://etools.mla.com.au/ptool/?v=${SubVersion}&r=${Revision}`,
            // onPress: `http://10.1.1.31:8080/?v=${SubVersion}`,
            thumbnail: require('../assets/images/apps/phosphorus-tool-feature-img-624x416.jpg'),
            displayOrder: 20,
            comingSoon: false,
            underMaintenance: false
        },
        {
            type: EWebPackageType.Tool,
            icon: "md-build",
            label: "Pasture trial network",
            description: `This tool allows you to assess and compare the performance of more than 100 pasture varieties across the key pasture species for the red meat industry, including phalaris, cocksfoot, tall fescue, perennial and annual ryegrass, sub-clover and lucerne.`,
            onPress: `https://etools.mla.com.au/ptn/?v=${SubVersion}&r=${Revision}`,
            // onPress: `https://tools.mla.com.au/ptn/`,
            // onPress: `http://10.1.1.31:8083/?v=${SubVersion}`,
            thumbnail: require('../assets/images/apps/pasture-trial-network-feature-img-624x416.jpg'),
            displayOrder: 50,
            comingSoon: false,
            underMaintenance: false
        },
        {
            type: EWebPackageType.Tool,
            icon: "md-build",
            label: "Feedbase planning and budgeting tool",
            description: `This tool assists with: planning rotational grazing systems, determining appropriate stocking rates, calculating pasture growth rates, determining how long paddocks will last and calculating the most economical ration stock.`,
            onPress: `https://etools.mla.com.au/fbrp/?v=${SubVersion}&r=${Revision}`,
            // onPress: `http://10.1.1.31:8080/?v=${SubVersion}`,
            thumbnail: require('../assets/images/apps/feed-budget-planning-feature-img-624x416.jpg'),
            displayOrder: 30,
            comingSoon: false,
            underMaintenance: false
        },

        // calculators
        {
            type: EWebPackageType.Calculator,
            icon: "md-calculator",
            label: "Stocking rate calculator",
            description: `The stocking rate calculator is designed to determine the number of cattle or sheep you should put into a paddock based on its carrying capacity.`,
            onPress: `https://etools.mla.com.au/src/?v=${SubVersion}&r=${Revision}`,
            //onPress: `http://10.1.1.31:8056/?v=${SubVersion}&r=${Revision}`,
            thumbnail: require('../assets/images/apps/stocking-rate-calculator-feature-img-624x416.jpg'),
            displayOrder: 10,
            comingSoon: false,
            underMaintenance: false
        },
        {
            type: EWebPackageType.Calculator,
            icon: "md-calculator",
            label: "Pasture improvement calculator",
            description: `This calculator helps to determine the costs and benefits of resowing pastures compared to current practice.`,
            onPress: `https://etools.mla.com.au/pic/?v=${SubVersion}&r=${Revision}`,
            // onPress: `http://10.1.1.31:8082/?v=${SubVersion}`,
            thumbnail: require('../assets/images/apps/pasture-improvement-calculator-feature-img-624x416.jpg'),
            displayOrder: 40,
            comingSoon: false,
            underMaintenance: false
        },
        {
            type: EWebPackageType.Calculator,
            icon: "md-calculator",
            label: "Feed demand calculator",
            description: `This calculator allows producers to gain an appreciation of the pattern of feed supply and demand over a twelve-month period, the location of "feed gaps" and the ways in which modifying the livestock enterprise might help to close these gaps.`,
            onPress: `https://etools.mla.com.au/fdc/?v=${SubVersion}&r=${Revision}`,
            // onPress: `http://10.1.1.31:8080/?v=${SubVersion}`,
            thumbnail: require('../assets/images/apps/feed-demand-calculator-feature-img-624x416.jpg'),
            displayOrder: 60,
            comingSoon: false,
            underMaintenance: true
        }
    ];

    public getPackages(type?: EWebPackageType): WebPackage[] {
        return this.packages.filter(x => !type || x.type == type).sort((a, b) => {
            const a_cs = a.comingSoon ? 0 : 1;
            const b_cs = b.comingSoon ? 0 : 1;

            if(a_cs < b_cs) return 1;
            if(a_cs > b_cs) return -1;

            if(a.displayOrder < b.displayOrder) return -1;
            if(a.displayOrder > b.displayOrder) return 1;

            return 0;
            // return a.displayOrder - b.displayOrder;
        });
        // return this.packages.filter(x => !type || x.type == type).sort((a, b) => {
        //     const A = a.label.toLowerCase();
        //     const B = b.label.toLowerCase();
        //     if (A < B) { return -1; }
        //     if (A > B) { return 1; }
        //     return 0;
        // });
    }
}

export const WebPackageService = new WebPackageServiceImpl();
export default WebPackageService;