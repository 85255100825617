import WebViewMessage from "./message";

interface EvalMessageData {
    code: string;
}

export default class EvalMessage extends WebViewMessage<EvalMessageData> {
    public async onReceivedMessage(data: EvalMessageData) {
        // //let code = `(() => {${data.code}})();`;
        // console.log('Run code?', data.code);
        try {
            let response = eval(data.code);
            this.reply(response);
        } catch (ex) {
            this.reply({ error: ex.message });
        }
    }
}