import { Ionicons } from '@expo/vector-icons';
import * as React from 'react';
import { StyleProp, TextStyle } from 'react-native';

import Colors from '../constants/Colors';

export default function TabBarIcon(props: { name: string, focused?: boolean, size?: number, style?: StyleProp<TextStyle> }) {
  const styles: object = (Array.isArray(props.style) ? props.style.reduce((agg: any, val: any) => ({ ...agg, ...val })) : props.style) || {};
  const style = { marginBottom: -3, ...styles };
  // console.log('style', style, Array.isArray(props.style));
  return (
    <Ionicons
      name={props.name}
      size={props.size || 25}
      style={style}
      color={props.focused ? Colors.tabIconSelected : Colors.tabIconDefault}
    />
  );
}
