import { useState, useEffect } from "react";
// import { Notifications } from 'expo';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import * as Permissions from 'expo-permissions';
import { Platform, Vibration } from "react-native";
import { EventSubscription, EventEmitter } from 'fbemitter';
import { getUserMeta, Endpoints } from "../common/user";
import { Subscription } from '@unimodules/core';
import { getUserHeaders } from "./UserService";
// import { getUserHeaders } from "./UserService";
import AsyncStorage from '@react-native-async-storage/async-storage';

export interface Notification {
    origin: 'selected' | 'received';
    data: any;
    remote: boolean;
    isMultiple: boolean;
    notificationId: number;
    userText: string | null;
    actionId: any;
};

enum NotificationUpdateResult {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    INVALID_TOKEN = 'INVALID_TOKEN'
}

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: true,
    }),
});

const ASDataKey = 'mla_noty';

class NotificationServiceImpl {
    private token: Notifications.ExpoPushToken | null = null;

    private subscription: EventSubscription | undefined;
    private expoTokenChangedListener: Subscription | undefined;
    private emitter = new EventEmitter();

    private addNotification(notification: Notification) {
        this.emitter.emit('recieved', notification);
    }

    public getToken() {
        return this.token?.data || '';
    }

    addListener(listener: (notification: Notification) => void) {
        return this.emitter.addListener('recieved', listener);
    }

    async reportNotificationTokenToServer(): Promise<keyof typeof NotificationUpdateResult> {
        try {
            const headers = await getUserHeaders();
            const newExpoToken = this.getToken();
            const oldExpoToken = await this.getOldExpoToken();
            const data = {
                ...getUserMeta(newExpoToken),
                oldExpoToken,
            };
            // console.log('Reporting to server', headers, data);
            let response = await fetch(Endpoints.Notifications, {
                credentials: 'same-origin',
                method: 'POST',
                body: JSON.stringify(data),
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers,
                }
            })
                .then(r => r.text())
                .then(raw => {
                    // console.log('raw', raw);
                    return JSON.parse(raw);
                })
                .then(r => {
                    this.setOldExpoToken(newExpoToken);
                    return r;
                })
                .catch(err => {
                    console.log(err);
                });
            // console.log('response', response);
            if (response && typeof response == 'object' && 'token' in response && response.token.length > 0) {
                return 'SUCCESS';
            }
        }
        catch (ex) {
            //console.error(ex);
        }
        return 'FAILED';
    }

    async getOldExpoToken() {
        return await AsyncStorage.getItem(`${ASDataKey}_oep`);
    }

    async setOldExpoToken(token: string | null) {
        await AsyncStorage.setItem(`${ASDataKey}_oep`, token || '');
    }

    async start() {
        try {
            // stop anything previously running
            await this.stop();
            /*
                Vapid was accidentally published apparently, so web is not really supported but we can add support in if required
                2020 Update: https://github.com/expo/expo/issues/6895
            */
            if (Platform.OS == 'web') {
                return;
            }

            console.log('starting notification service');

            // register for token
            if (Constants.isDevice) {
                const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
                let finalStatus = existingStatus;
                if (existingStatus !== 'granted') {
                    const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
                    finalStatus = status;
                }
                if (finalStatus !== 'granted') {
                    console.warn('Failed to get push token for push notification!');
                    return;
                }
                this.token = await Notifications.getExpoPushTokenAsync();

                // console.log('this.token', this.token);

                if (this.token?.data) {
                    await this.reportNotificationTokenToServer();
                    // console.log('resp', resp);
                }
            } else {
                // console.warn('Must use physical device for Push Notifications');
            }

            if (Platform.OS === 'android') {
                Notifications.setNotificationChannelAsync('default', {
                    // name: 'default',
                    // sound: true,
                    // priority: 'max',
                    // vibrate: [0, 250, 250, 250],

                    name: 'default',
                    importance: Notifications.AndroidImportance.MAX,
                    vibrationPattern: [0, 250, 250, 250],
                    lightColor: '#FF231F7C',
                });
            }

            // listen for notifications from token
            // this.subscription = Notifications.addListener(notification => {
            //     Vibration.vibrate([0, 250, 250, 250]);
            //     this.addNotification(notification as Notification);
            // });

            this.expoTokenChangedListener?.remove();
            this.expoTokenChangedListener = undefined;
            this.expoTokenChangedListener = Notifications.addPushTokenListener(async (token: Notifications.DevicePushToken) => {
                let is_the_same = false;
                if (token && token.data == this.token?.data) {
                    is_the_same = true;
                }
                if (!is_the_same) {
                    console.log('Token changed'); //, token.data, this.token);
                    if (!this.token) {
                        this.token = await Notifications.getExpoPushTokenAsync();
                    } else {
                        this.token.data = token.data; //await Notifications.getExpoPushTokenAsync();
                    }
                    if (this.token?.data) {
                        let resp = await this.reportNotificationTokenToServer();
                        // console.log('resp', resp);
                    }
                }
            });

            // tapping on the badge doesnt appear to be clearing the badge
            Notifications.setBadgeCountAsync(0);

            return true;
        }
        catch (ex) {
            console.error(ex);
        }
        return false;
    }

    async stop() {
        this.subscription?.remove();
        this.subscription = undefined;
        this.expoTokenChangedListener?.remove();
        this.expoTokenChangedListener = undefined;
        this.token = null;
    }
}

export const NotificationService = new NotificationServiceImpl();
export default NotificationService;