import { Platform, Alert } from "react-native";

export async function confirm(message: string): Promise<boolean> {
    switch (Platform.OS) {
        case 'ios':
        case 'android':
            return new Promise<boolean>(r => {
                Alert.alert(
                    'Notice',
                    message,
                    [
                        {
                            text: 'Yes', onPress: () => {
                                r(true);
                            }
                        },
                        {
                            text: 'No', onPress: () => {
                                r(false);
                            }, style: 'cancel'
                        },
                    ],
                    { cancelable: false }
                )
            });

        default:
            return Promise.resolve<boolean>(window.confirm(message));
    }
}