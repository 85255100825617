
import { WebView as NativeWebView, WebViewProps } from 'react-native-webview'
import { Platform, BackHandler, ActivityIndicator, StyleSheet } from 'react-native';
import { processWebViewEvent } from '.';
import React, { useEffect, useRef, useState } from 'react';
import useNotifications from '../hooks/useNotifications';
import { WebViewSourceUri } from 'react-native-webview/lib/WebViewTypes';
import { useNavigation } from '@react-navigation/native';

export const WebViewJsApi = (additionalJavascript?: string) => `
window.onerror = function() {
  alert('error')
};
if(!('AppData' in window)) {
  window.AppData = { Platform: '${Platform.OS}' };
  try {
    window.AppData.MessageID = 0;
    window.AppData.Messages = {};
    window.AppData.Notifications = [];

    function addNativeNotification(notification) {
      window.AppData.Notifications.push(notification);
      if('onNativeNotificationReceived' in window) {window.onNativeNotificationReceived(notification);}
    }
    window.addNativeNotification = addNativeNotification;

    function sendNativeMessage(packet) {
      const replyId = (window.AppData.MessageID++) + '_msg_' + (+new Date());
      return new Promise((r, e) => {
        window.AppData.Messages[replyId] = (data) => {
          r(data);
          delete window.AppData.Messages[replyId];
        };
        window.ReactNativeWebView.postMessage(JSON.stringify({...packet, replyId}));
      });
    };
    window.sendNativeMessage = sendNativeMessage;

    ${additionalJavascript || ''}

    (async () => {
      var waitCount = 0;
      while(!('onApplicationBooted' in window) && waitCount++ < 1000) {
        await new Promise((r) => window.setTimeout(r, 10));
      }
      if('onApplicationBooted' in window) {
        window.onApplicationBooted();
      }
      else {
        if(!document.getElementById("form1")) {
          /*alert('Failed to load the application');*/
        }
      }
    })();
  } catch(ex) {
    alert('Startup error' + ex);
  }
}
//return true;`; // dont return like initially thought, ios breaks

export function WebView(props: WebViewProps) {
  const [loading, setLoading] = useState(false);
  const ref = React.createRef<NativeWebView>();
  const nav = useNavigation();
  useNotifications(notification => {
    ref?.current?.injectJavaScript(`window.addNativeNotification(${JSON.stringify(notification)});`);
  });
  let uri = props.source as WebViewSourceUri;

  if (Platform.OS == 'web') {
    return <iframe style={{ border: 0, height: '100%' }} src={uri?.uri} onLoad={e => !!props.onLoad ? props.onLoad(null as any) : undefined} />
  }

  // const [nav, setNav] = useState<(string | null)[]>([null]);
  // const currentUrl = nav[nav.length - 1] ?? uri?.uri;
  // console.log('currentUrl', currentUrl);
  const currentUrl = uri?.uri;

  const canGoBack = useRef(false);
  const backPressed = useRef(false);

  useEffect(() => {
    const onBackPressed = () => {
      backPressed.current = true;
      // console.log('onBackPressed', canGoBack.current, !!ref.current);
      if (canGoBack.current) {
        ref.current?.goBack();
        return true;
      }
      return false;
      // if (nav.length > 1) {
      //   nav.pop();
      //   setNav([...nav]);
      //   return true;
      // }
      // return false;
    };

    BackHandler.addEventListener('hardwareBackPress', onBackPressed);

    return function () {
      BackHandler.removeEventListener('hardwareBackPress', onBackPressed);
    }
  }, [/*nav,*/ ref]);

  return <>
    <NativeWebView
      {...props}
      source={{ uri: currentUrl }}
      style={styles.webview}
      cacheEnabled={true}
      // cacheMode={'LOAD_CACHE_ELSE_NETWORK'} // this setting would prevent the sub apps updating to the latest versions when pushed to the website. LOAD_DEFAULT is used instead now
      cacheMode={'LOAD_DEFAULT'}
      javaScriptEnabled={true}
      ref={ref}
      injectedJavaScript={WebViewJsApi(props.injectedJavaScript)}
      onMessage={(event) => {
        if (props.onMessage) props.onMessage(event);
        if (ref?.current) {
          processWebViewEvent(ref?.current, event);
        }
      }}
      onNavigationStateChange={(navState) => {
        // console.log('navState', navState, navState.canGoBack);
        canGoBack.current = navState.canGoBack;
        if (backPressed.current && navState.url == 'about:blank') {
          canGoBack.current = false;
          nav.goBack();
        }
        backPressed.current = false;
      }}
      onShouldStartLoadWithRequest={(evt) => {
        // console.log(`[onShouldStartLoadWithRequest] ${evt.url}`);
        setLoading(true);
        // setNav([...nav, evt.url]);
        return true;
      }}
      onLoadEnd={(evt) => {
        if (props.onLoadEnd) {
          props.onLoadEnd(evt);
        }
        if (loading) {
          setLoading(false);
        }
      }}
    />
    {loading && <ActivityIndicator size="large" color="#fab907"
      style={styles.indicator}
    />}
  </>
}

const styles = StyleSheet.create({
  webview: {

  },
  indicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center'
  }
});