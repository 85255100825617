import WebViewMessage from "./message";
import NotificationService from "../services/NotificationService";

interface NotificationsData {

}

export default class NotificationsMessage extends WebViewMessage<NotificationsData> {
    public async onReceivedMessage(data: NotificationsData) {
        this.reply({
            token: NotificationService.getToken(),
        });
    }
}