import * as Sentry from 'sentry-expo';
import { Ionicons } from '@expo/vector-icons';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
// import { SplashScreen } from 'expo';
import * as SplashScreen from 'expo-splash-screen';
import * as Font from 'expo-font';
import * as React from 'react';
import { Platform, StatusBar, StyleSheet, View, Text, Alert, LogBox } from 'react-native';
import * as Updates from 'expo-updates';
import Constants from 'expo-constants';

import BottomTabNavigator from './navigation/BottomTabNavigator';
import LinkingConfiguration from './navigation/LinkingConfiguration';
import useNotifications from './hooks/useNotifications';
import NotificationService from './services/NotificationService';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import HomeScreen from './screens/HomeScreen';
import TabBarIcon from './components/TabBarIcon';
import ToolsScreen from './screens/ToolsScreen';
import CalculatorsScreen from './screens/CalculatorsScreen';
import Colors from './constants/Colors';

import getTheme from './native-base-theme/components';
import material from './native-base-theme/variables/material';
import { StyleProvider } from 'native-base';
import { installWebPackages } from './tools/installer';

import * as WebBrowser from 'expo-web-browser';
import { SubWebView } from './common/SubWebView';
import { SubVersion } from './services/WebPackageService';

import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import WebViewScreen from './screens/WebViewScreen';
import { Revision } from './constants/Version';
import { enableScreens } from 'react-native-screens';
enableScreens();

Sentry.init({
  dsn: "https://fd961bb946964f73bec0edb544a3c8d2@o483106.ingest.sentry.io/5534332",
  enableInExpoDevelopment: false,
  debug: Constants.debugMode, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
});

LogBox.ignoreLogs(['Constants (expo-constants)']); // cannot find the dependency that causes this

if (Platform.OS == 'web') {
  Sentry.Browser.configureScope(scope => {
    scope.setTags({
      'Revision': `${Revision}`,
      'appVersion': `${Constants.nativeAppVersion}`,
      'buildVersion': `${Constants.nativeBuildVersion}`,
    });
  });
} else {
  Sentry.Native.configureScope(scope => {
    scope.setTags({
      'Revision': `${Revision}`,
    });
  });
}

const completedAuth = WebBrowser.maybeCompleteAuthSession({ skipRedirectCheck: true });

// if (Platform.OS == 'web') {
//   navigator.serviceWorker.getRegistrations()
//     .then(function (registrations) {
//       for (var registration of registrations) {
//         registration.unregister();
//       }
//     }).catch(function (err) {
//       console.error('Service Worker registration failed: ', err);
//     });
// }

// console.log('completedAuth', completedAuth);

const Stack = createStackNavigator();
const BottomTab = createMaterialBottomTabNavigator();

interface AppProps {
  skipLoadingScreen?: boolean;
}

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#007549',
    notification: '#007549',

    // primary: string;
    // background: string;
    // surface: string;
    // accent: string;
    // error: string;
    // text: string;
    // onSurface: string;
    // onBackground: string;
    // disabled: string;
    // placeholder: string;
    // backdrop: string;
    // notification: string;
  },
};

// function renderMobile() {
//   return renderWeb();
//   // return <View style={styles.mobileContainer}>
//   //   <Stack.Navigator headerMode={'none'} initialRouteName="Hub">
//   //     <Stack.Screen name="Hub" component={HomeScreen} />
//   //     <Stack.Screen name="WebView" component={WebViewScreen} />
//   //   </Stack.Navigator>
//   //   <Text>Test</Text>
//   // </View>;
//   // return <BottomTab.Navigator initialRouteName={"Home"}
//   //   // activeColor="#f0edf6"
//   //   // inactiveColor="#3e2465"
//   //   barStyle={{ backgroundColor: Colors.tabBar }}
//   //   screenOptions={{

//   //   }} >
//   //   <BottomTab.Screen
//   //     name="Home"
//   //     component={HomeScreen}
//   //     options={{
//   //       title: 'Home',
//   //       tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-home" />,
//   //       // tabBarLabel: ({ focused }) => <Text style={{ fontSize: 10, paddingBottom: 3, color: focused ? '#007549' : 'lightgrey' }}>Home</Text>,
//   //     }}
//   //   />
//   //    <BottomTab.Screen
//   //     name="WebView"
//   //     component={WebViewScreen}
//   //     options={({ navigation, route }) => {
//   //       console.log('navigation, route', navigation, route);
//   //       return {
//   //         title: '',
//   //         // tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-build" />,
//   //         // tabBarLabel: ({ focused }) => <Text style={{ fontSize: 10, paddingBottom: 3, color: focused ? '#007549' : 'lightgrey' }}>Tools</Text>,
//   //       }
//   //     }}
//   //   /> 
//   // </BottomTab.Navigator>
// }

// function renderWeb() {
//   return <Stack.Navigator headerMode={'none'} initialRouteName="hub">
//     <Stack.Screen name="hub" component={HomeScreen} />
//     <Stack.Screen name="WebView" component={WebViewScreen} />
//   </Stack.Navigator>;
// }

export default function App(props: AppProps) {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);

  React.useEffect(() => {
    const handle = Updates.addListener((evt) => {
      if (evt.type == Updates.UpdateEventType.UPDATE_AVAILABLE) {
        alert(`An update is available and will be applied next restart of the application`);
      }
    });

    return function () {
      handle.remove();
    }
  }, []);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        await SplashScreen.preventAutoHideAsync().catch(ex => { });

        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
        });

        await NotificationService.start();

        //await installWebPackages();
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
        // alert(e.message);
        alert(`Application did not load correctly`);
      } finally {
        setLoadingComplete(true);
        await SplashScreen.hideAsync().catch(ex => { });
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return null;
  } else {
    return (
      <PaperProvider theme={theme}>
        <View style={styles.container}>
          {/* <SubWebView uri={`http://10.1.1.31:8080/?v=${SubVersion}&platform=${Platform.OS}`} /> */}
          {/* {Platform.OS === 'ios' && <StatusBar barStyle="dark-content" />} */}
          {/* <NavigationContainer linking={LinkingConfiguration} fallback={<div />}>
          {/ * <SubWebView uri={`https://etools.mla.com.au/?v=${SubVersion}&platform=${Platform.OS}`} /> * /}
          <SubWebView uri={`http://10.1.1.31:8080/?v=${SubVersion}&platform=${Platform.OS}`} />
        </NavigationContainer> */}
          {Platform.OS === 'ios' && <StatusBar barStyle="dark-content" />}
          {Platform.OS == 'web' && <HomeScreen />}
          {Platform.OS != 'web' && <NavigationContainer linking={LinkingConfiguration}>
            <Stack.Navigator headerMode={'none'} initialRouteName="hub">
              <Stack.Screen name="hub" component={HomeScreen} />
              <Stack.Screen name="WebView" component={WebViewScreen} />
            </Stack.Navigator>
            {/* {Platform.OS != 'web' && renderMobile()}
            {Platform.OS == 'web' && renderWeb()} */}

            {/* <BottomTab.Navigator initialRouteName={"Home"}
            // activeColor="#f0edf6"
            // inactiveColor="#3e2465"
            barStyle={{ backgroundColor: Colors.tabBar }}
            screenOptions={{

            }} >
            <BottomTab.Screen
              name="Home"
              component={HomeScreen}
              options={{
                title: 'Home',
                tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-home" />,
                // tabBarLabel: ({ focused }) => <Text style={{ fontSize: 10, paddingBottom: 3, color: focused ? '#007549' : 'lightgrey' }}>Home</Text>,
              }}
            />
            <BottomTab.Screen
              name="Tools"
              component={ToolsScreen}
              options={({ navigation, route }) => {
                console.log('navigation, route', navigation, route);
                return {
                  //title: '',
                  tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-build" />,
                  // tabBarLabel: ({ focused }) => <Text style={{ fontSize: 10, paddingBottom: 3, color: focused ? '#007549' : 'lightgrey' }}>Tools</Text>,
                }
              }}
            />
            <BottomTab.Screen
              name="Calculators"
              component={CalculatorsScreen}
              options={{
                //title: 'Calculators',
                tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="md-calculator" />,
                // tabBarLabel: ({ focused }) => <Text style={{ fontSize: 10, paddingBottom: 3, color: focused ? '#007549' : 'lightgrey' }}>Calculators</Text>,
              }}
            />
          </BottomTab.Navigator> */}
          </NavigationContainer>}
          {/* <NavigationContainer linking={LinkingConfiguration}>
          <Stack.Navigator headerMode={'none'}>
            <Stack.Screen name=" " component={BottomTabNavigator} />
          </Stack.Navigator>
        </NavigationContainer> */}
        </View>
      </PaperProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f3f1ef',
    // paddingTop: Constants.statusBarHeight
  },
  mobileContainer: {
    flex: 1,
    backgroundColor: '#f3f1ef',
    alignContent: 'center'
  }
});
