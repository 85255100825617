import WebViewMessage from "./message";
import { Linking } from 'react-native'

interface LinkingMessageData {
    operation: keyof Linking;

    openURL?: string;
}

export default class LinkingMessage extends WebViewMessage<LinkingMessageData> {
    public async onReceivedMessage(data: LinkingMessageData) {
        if (data.operation == 'openURL') {
            if (data.openURL) {
                const resultValue = await Linking.openURL(data.openURL);
                this.reply({ result: true, resultValue });
                return;
            }
        }
        else this.reply({ result: false });
    }
}