import * as React from 'react';
import { signOutAsync, signInAsync, getUserData, UserData, Notifications, NotificationTypes } from '../services/UserService';

export default function useAppAuth(onLoaded?: () => void) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [userData, setUserData] = React.useState<UserData | null>(null);
  // const [refresh, setRefresh] = React.useState(0);

  // const update = async () => {
  //   setIsLoading(true);
  //   const u = await getUserData();
  //   setIsLoading(false);
  //   setUserData(u);
  //   if (onLoaded) onLoaded();
  // };

  const onSignInAsyncBefore = () => {
    setIsLoading(true);
    // setRefresh(refresh + 1);
  };
  const onSignInAsyncAfter = () => {
    // setIsLoading(false);
    // setRefresh(refresh + 1);
    getUserData();
  };
  const onSignOutAsyncBefore = () => {
    setIsLoading(true);
    // console.log('onSignOutAsyncBefore');
    setUserData(null);
    if (onLoaded) onLoaded();
    // setRefresh(refresh + 1);
  };
  const onSignOutAsyncAfter = () => {
    setIsLoading(false);
    // setRefresh(refresh + 1);
  };
  const onGetUserDataBefore = () => {
    setIsLoading(true);
    // setRefresh(refresh + 1);
  };
  const onGetUserDataAfter = (data: UserData | null) => {
    setIsLoading(false);
    setUserData(data);
    // console.log('onGetUserDataAfter', data);
    if (onLoaded) onLoaded();
  };

  React.useEffect(() => {
    const listeners = [
      Notifications.addListener(NotificationTypes.signInAsyncBefore, onSignInAsyncBefore),
      Notifications.addListener(NotificationTypes.signInAsyncAfter, onSignInAsyncAfter),
      Notifications.addListener(NotificationTypes.signOutAsyncBefore, onSignOutAsyncBefore),
      Notifications.addListener(NotificationTypes.signOutAsyncAfter, onSignOutAsyncAfter),
      Notifications.addListener(NotificationTypes.getUserDataBefore, onGetUserDataBefore),
      Notifications.addListener(NotificationTypes.getUserDataAfter, onGetUserDataAfter),
    ];

    // initiate checking to see if the user is signed in already
    getUserData();

    return function () {
      listeners.map(l => l.remove());
    };
  }, []);

  return {
    userData,
    isSignedIn: !!userData?.user?.login,
    isLoading,
    // testAsync: async () => {
    //   getUserData()
    // },
    signInAsync,
    signOutAsync,
    getUserData,
  };
}
