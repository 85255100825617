import * as WebBrowser from 'expo-web-browser';
import { Image, StyleSheet, Text, View, Button, AppState, Alert, Platform } from 'react-native';
import * as AuthSession from 'expo-auth-session';
import * as Linking from 'expo-linking';
import { useAuthRequest, ResponseType } from 'expo-auth-session';
import qs from 'qs';
import { getQueryStringParameters } from '../common';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { EventSubscription, EventEmitter } from 'fbemitter';
import Constants from 'expo-constants';
import Base64 from '../common/base64';
// import NotificationService from './NotificationService';
import { getUserMeta, Endpoints } from '../common/user';
import * as Sentry from 'sentry-expo';

const UserDataKey = 'mla_user';
const redirectUri = AuthSession.makeRedirectUri({ useProxy: false });

export const Notifications = new EventEmitter();
export enum NotificationTypes {
    signInAsyncBefore = 'signInAsyncBefore',
    signInAsyncAfter = 'signInAsyncAfter',
    signOutAsyncBefore = 'signOutAsyncBefore',
    signOutAsyncAfter = 'signOutAsyncAfter',
    fetchUserInfoBefore = 'fetchUserInfoBefore',
    fetchUserInfoAfter = 'fetchUserInfoAfter',
    getUserDataBefore = 'getUserDataBefore',
    getUserDataAfter = 'getUserDataAfter'
}

export enum SignInAsyncResult {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    INVALID_TOKEN = 'INVALID_TOKEN',
    NO_URL = 'NO_URL'
}
export async function signInAsync(expoToken: string) {
    let result: SignInAsyncResult | null = null;
    // await signOutAsync();

    Notifications.emit(NotificationTypes.signInAsyncBefore, result);

    let token: string | null = null;
    let userMeta = getUserMeta(expoToken/*NotificationService.getToken()*/);
    // console.log('SIGNIN', {
    //     userMeta,
    //     json: JSON.stringify(userMeta),
    // });
    var meta = Base64.btoa(unescape(encodeURIComponent(JSON.stringify(userMeta))));
    let returnUrl = redirectUri + (redirectUri.indexOf('?') == -1 ? '?' : '&') + `meta=${meta}`;
    const authUrl = `${Endpoints.Login}?redirect_to=${encodeURIComponent(returnUrl)}`;
    // console.log('returnUrl: ', returnUrl);
    // console.log('authUrl: ', authUrl);

    // console.log('native crash?');
    // Sentry.Native.nativeCrash();
    // Sentry.Native.captureException(new Error('TEST!'));

    const resp = await WebBrowser.openAuthSessionAsync(
        authUrl,
        returnUrl,
    );

    // console.log('Sign in response', JSON.stringify(resp));

    if (resp && 'url' in resp) {
        const q = getQueryStringParameters(resp.url);

        if (typeof q.token == 'string' && q.token.trim().length > 0) {
            console.log('Aquiring user info with token', q.token);
            const user = await fetchUserInfo({
                token: q.token,
            });
            if (user && user.login) {
                await setUserData({
                    token: q.token,
                    user,
                });
                result = SignInAsyncResult.SUCCESS;
                token = q.token;
            } else {
                result = SignInAsyncResult.FAILED;
            }
        } else {
            result = SignInAsyncResult.INVALID_TOKEN;
        }
    } else {
        result = SignInAsyncResult.NO_URL;
    }

    Notifications.emit(NotificationTypes.signInAsyncAfter, result);

    if (result != SignInAsyncResult.SUCCESS) {
        Sentry.Native.captureException(new Error(`Failed to sign in: ${result}`));
    }

    return {
        result,
        userMeta,
        token
    };
}

export interface UserSiteLocation {
    ID: number;
    name: string;
}

export const DeveloperMode = {
    Live: 'live',
    DevOnly: 'dev',
} as const;

export type DeveloperModes = (typeof DeveloperMode)[keyof typeof DeveloperMode];

export interface UserInfo {
    login: string;
    nicename: string;
    email: string;
    display_name: string;
    site_locations: UserSiteLocation[];
    developerMode?: DeveloperModes[];
}

export function isDeveloperMode(user: UserInfo | null | undefined, mode: DeveloperModes) {
    if (user) {
        if (typeof user.developerMode == 'object' && Array.isArray(user.developerMode)) {
            return user.developerMode.filter(m => mode === m).length > 0;
        }
    }
    return false;
}

export interface UserData {
    token?: string;
    user?: UserInfo;
}

export async function getUserHeaders(data?: UserData | null): Promise<Headers | string[][] | { [key: string]: string }> {
    if (!data) {
        data = await getUserData();
    }

    if (!data) {
        // console.error('No user data');
        return {};
    }

    if (!data.token) {
        // console.error('No user token');
        return {};
    }

    return {
        'X-MLA-Token': data.token,
        'X-MLA-Ini': Constants.installationId,
    };
}

export async function fetchUserInfo(data?: UserData | null): Promise<UserInfo | null> {
    var info: UserInfo | null = null;
    if (!data) {
        data = await getUserData();
    }

    if (!data) {
        // console.error('No user data');
        return null;
    }

    if (!data.token) {
        // console.error('No user token');
        return null;
    }

    Notifications.emit(NotificationTypes.fetchUserInfoBefore, info);

    try {
        // console.log('Querying: ' + Endpoints.UserInfo, data.token, Constants.installationId);
        var response: { data: any, status: boolean } = await fetch(Endpoints.UserInfo, {
            credentials: 'same-origin',
            headers: {
                'X-MLA-Token': data.token,
                'X-MLA-Ini': Constants.installationId,
            }
        })
            .then(r => r.text())
            .then(j => {
                // console.log('response', j);
                return JSON.parse(j);
            });
        // .then(r => r.json());
        // console.log('response', response);

        if (response && response.status && response.data && typeof response.data.user == 'object') {
            if (response
                && typeof response.data.user.login == 'string'
                && typeof response.data.user.nicename == 'string'
                && typeof response.data.user.email == 'string'
                && typeof response.data.user.display_name == 'string'
            ) {
                info = response.data.user;

                if (info) {
                    if ('site_locations' in response.data.user && info.site_locations) {
                        info.site_locations = response.data.user.site_locations.map((sl: any) => ({ ID: parseInt(sl.ID), name: sl.name }));
                    }
                    info.site_locations = info.site_locations || [];
                }
            }
        }
    } catch (ex) {
        console.error('Err: ' + ex.message);
    }

    // console.log('info', info);

    Notifications.emit(NotificationTypes.fetchUserInfoAfter, info);

    return info;
}

export async function getUserData(): Promise<UserData | null> {
    let userData: UserData | null = null;
    Notifications.emit(NotificationTypes.getUserDataBefore, userData);
    try {
        let data = await AsyncStorage.getItem(UserDataKey);
        if (data) {
            let user = JSON.parse(data) as UserData;
            if (user.user?.display_name) {
                userData = user;
            }
        }
    }
    catch (ex) {
        console.log('Removed user data as it was invalid');
        await AsyncStorage.removeItem(UserDataKey);
    }
    Notifications.emit(NotificationTypes.getUserDataAfter, userData);
    return userData;
}

async function setUserData(data: UserData) {
    await AsyncStorage.setItem(UserDataKey, JSON.stringify(data));
}

export async function signOutAsync() {
    Notifications.emit(NotificationTypes.signOutAsyncBefore);
    await AsyncStorage.removeItem(UserDataKey);
    Notifications.emit(NotificationTypes.signOutAsyncAfter);
}