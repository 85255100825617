import WebViewMessage from "./message";
import * as Print from 'expo-print';

interface PrintMessageData {
    html: string;
}

export default class PrintMessage extends WebViewMessage<PrintMessageData> {
    public async onReceivedMessage(data: PrintMessageData) {

        // transform local/relative CSS directories to their full URL.
        // expos print (webview) does not load relatively anymore, so we need to pass the full url.
        if (this.webView?.props?.source && 'uri' in this.webView.props.source) {
            const url = this.webView.props.source.uri;
            if (typeof url == 'string') {
                let baseUrl = url.split('?')[0];
                
                // while (data.html.indexOf('src="js') > -1) {
                //     data.html = data.html.replace('src="js', 'src="' + baseUrl + 'js');
                // }
                while (data.html.indexOf('<link href="css') > -1) {
                    data.html = data.html.replace('<link href="css', '<link href="' + baseUrl + 'css');
                }
            }
        }

        await Print.printAsync({
            html: data.html,
            markupFormatterIOS: data.html,
        })
        .catch(ex => {
            // ios will alert: Printing did not complete
        });

        this.reply({});
    }
}