import WebViewMessage from "./message";
import { Alert, AlertStatic, AlertButton, AlertOptions } from "react-native";

interface AlertMessageData {
    title?: string;
    message: string;
    buttons?: AlertButton[];
    options?: AlertOptions;
}

export default class AlertMessage extends WebViewMessage<AlertMessageData> {
    public async onReceivedMessage(data: AlertMessageData) {
        // alert(data.message);
        // console.log(data);
        let buttonIndex = await new Promise<number | undefined>((r, e) => {
            let buttons = data.buttons;
            if (buttons) {
                buttons.map((b, ix) => b.onPress = () => {
                    r(ix)
                })
            } else buttons = [
                {
                    text: 'Ok',
                    onPress: () => {
                        r(undefined);
                    }
                }
            ]
            Alert.alert(data.title || 'Alert', data.message, buttons, data.options);
        });
        this.reply({ buttonIndex });
    }
}