import React, { useEffect, useState, useLayoutEffect } from 'react';
import { View, StyleSheet, Button, Text, Platform, TouchableOpacity, ScrollView, RefreshControl } from 'react-native';
import { WebView } from '../webview-bridge/WebView';
import { useRoute, useNavigation } from '@react-navigation/native';
// import { Navigation } from '../navigation/Constants';
import { BackHandler } from 'react-native';
import TabBarIcon from '../components/TabBarIcon';
import { FAB, TouchableRipple } from 'react-native-paper';
import { StackActions } from '@react-navigation/native';
import { StatusBar } from 'expo-status-bar';
import Constants from 'expo-constants';

// import * as FS from 'expo-file-system';
const loginBypass =
  `
  var mlal_f1 = document.getElementById("form1");
  if(mlal_f1) {
    mlal_f1.style.display = "flex";
    mlal_f1.style.justifyContent = "center";
    mlal_f1.style.zoom = "2.5";
    mlal_f1.style.fontFamily = "sans-serif";
    mlal_f1.style.marginTop = "50%";

    var mla_lu = document.getElementById('lg1_UserName');
    if(mla_lu) mla_lu.value = 'mla_test';
    var mla_lp = document.getElementById('lg1_Password');
    if(mla_lp) mla_lp.value = 'SteerEweRamBull55$';
  }
`;

// const wait = (timeout: number) => {
//   return new Promise(resolve => {
//     setTimeout(resolve, timeout);
//   });
// };

export default function WebViewScreen() {
  const navigation = useNavigation();
  const route = useRoute<any>();
  const { uri, title, source } = route.params;

  useEffect(() => {
    navigation.setOptions({
      headerTitle: title
    });
  }, [])

  // load hack - https://github.com/react-native-community/react-native-webview/issues/656#issuecomment-561067913
  const [renderedOnce, setRenderedOnce] = useState(['web', 'ios'].indexOf(Platform.OS) > -1);
  const updateSource = () => {
    setRenderedOnce(true);
    // console.log('Loading webview with:', uri, source, renderedOnce ? (source || { uri: uri }) : undefined);
  };

  console.log('Loading webview with:', uri, source, renderedOnce ? (source || { uri: uri }) : undefined, renderedOnce);

  // const [refreshing, setRefreshing] = React.useState(false);

  // const onRefresh = React.useCallback(() => {
  //   setRefreshing(true);

  //   wait(2000).then(() => setRefreshing(false));
  // }, []);

  // FS.readDirectoryAsync(FS.documentDirectory!).then(console.log)
  return (
    <View style={styles.container}>
      <StatusBar backgroundColor="#007549" animated={true} hidden />
      {/* <ScrollView
        contentContainerStyle={{
          flex: 1,
          backgroundColor: 'pink',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}> */}
      <WebView key={uri}
        //source={testurl ? { uri: testurl, baseUrl: FS.documentDirectory! } : undefined}
        source={renderedOnce ? (source || { uri: uri }) : undefined}
        nativeConfig={{ props: { webContentsDebuggingEnabled: true } }}
        //source={asd}
        // baseURL={'/'}
        // bounces={false}
        // style={{
        //   flex: 1,
        // }}

        originWhitelist={['*']}
        // startInLoadingState
        // scalesPageToFit
        domStorageEnabled={true}
        javaScriptEnabled={true}
        // onNavigationStateChange={(navState) => {
        //   console.log('navState', navState, navState.canGoBack);
        //   // evt.
        // }}

        // useWebKit={true}
        onLoad={updateSource}
        allowFileAccess={true}
        allowFileAccessFromFileURLs={true}
        allowUniversalAccessFromFileURLs={true}
        // allowingReadAccessToURL={'file://'}
        injectedJavaScript={loginBypass}
        // onError={syntheticEvent => {
        //   const { nativeEvent } = syntheticEvent;
        //   console.warn('WebView error: ', nativeEvent);
        //   alert('Failed to load: ' + nativeEvent.description);
        // }}
        // renderError={errorName => {
        //   console.log('errorName', errorName);
        //   return <View><Text>{JSON.stringify(errorName)}</Text></View>
        // }}
        onError={syntheticEvent => {
          const { nativeEvent } = syntheticEvent;
          if (Constants.debugMode) {
            console.warn('WebView error: ', nativeEvent);
            alert('WebView error: ' + nativeEvent.description);
          } else {
            console.warn('Failed to load');
            alert('Failed to load');
          }
        }}
        renderError={errorName => {
          if (Constants.debugMode) {
            console.error('errorName', errorName);
            return <View><Text>{JSON.stringify(errorName)}</Text></View>
          }
          return <View><Text>An error occurred</Text></View>
        }}
      />
      {/* </ScrollView> */}

      {/* <FAB
        style={styles.fab}
        small
        // label="Home"
        color={`#fff`}
        icon="home"
        onPress={() => {
          console.log('pop?');
          navigation.dispatch(StackActions.popToTop());
        }}
      /> */}
      {/* <TouchableOpacity
        activeOpacity={0.7}
        onPress={() => {
          navigation.dispatch(StackActions.popToTop());
        }}
        style={styles.fabContainerStyle}>
        <TabBarIcon name="md-home"
          style={styles.fabStyle}
        />
      </TouchableOpacity> */}
      <TouchableRipple
        onPress={() => {
          // console.log('pop?');
          navigation.dispatch(StackActions.popToTop());
        }}
        rippleColor="rgba(0, 0, 0, .32)"
        style={styles.fabContainerStyle}
      >
        <TabBarIcon name="md-home"
          style={styles.fabStyle}
        />
      </TouchableRipple>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    height: '100%'
  },

  fabContainerStyle: {
    position: 'absolute',
    marginTop: 8,
    marginRight: 8,
    right: 0,
    top: 0,
    width: 40,
    height: 40,
    backgroundColor: '#007549',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    padding: 0,
  },
  fabStyle: {
    top: -2,
    color: '#fff'
  },
  // fab: {
  //   position: 'absolute',
  //   // marginBottom: 20,
  //   // marginLeft: 16,
  //   // left: 0,
  //   // bottom: 0,
  //   marginTop: 8,
  //   marginRight: 8,
  //   right: 0,
  //   top: 0,
  //   backgroundColor: '#007549'
  //   // backgroundColor: '#007549'
  // },
});
