import { StyleSheet, Text, View, ActivityIndicator, Alert, Platform } from 'react-native';
import { RectButton, ScrollView } from 'react-native-gesture-handler';
import { Ionicons } from '@expo/vector-icons';
import * as React from 'react';
import Colors from '../constants/Colors';
import useAppAuth from '../hooks/useAppAuth';
import { Button, TouchableRipple } from 'react-native-paper';
// import { getUserData, signOutAsync, signInAsync, SignInAsyncResult } from '../services/UserService';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { confirm } from '../common/CrossPlatform';
import NotificationService from '../services/NotificationService';
import { Notifications, NotificationTypes, SignInAsyncResult } from '../services/UserService';
import * as Sentry from 'sentry-expo';

export default function MLALoginButton() {
  const [refresh, setRefresh] = React.useState(0);
  const { isSignedIn, isLoading, signInAsync, userData, signOutAsync } = useAppAuth(); //() => {
  //   //   console.log('MLALoginButton', isSignedIn, isLoading);
  //   //setRefresh(refresh + 1);
  // });
  // const [text, setText] = React.useState('Checking myMLA Login');
  // const [isLoading, setIsLoading] = React.useState(true);
  // const [isEnabled, setIsEnabled] = React.useState(false);
  // const [refresh, setRefresh] = React.useState(0);
  // const { signInAsync, getUserData, isSignedIn, isLoading, userData } = useAppAuth(() => {
  //   console.log('MLALoginButton', isSignedIn, isLoading);
  //   setRefresh(refresh + 1);
  // });
  // console.log('MLALoginButton a', isSignedIn, isLoading);

  // React.useEffect(() => {
  //   console.log('MLALoginButton isLoading', isSignedIn, isLoading);
  // }, [isLoading]);
  // React.useEffect(() => {
  //   console.log('MLALoginButton isSignedIn', isSignedIn, isLoading);
  // }, [isSignedIn]);

  // const update = () => {
  //   setText('Checking myMLA Login');
  //   getUserData().then(u => {
  //     if (u) {
  //       setText(`Welcome, ${u.user?.display_name}`);
  //       setIsLoading(false);
  //       setIsEnabled(true);
  //       //setIsEnabled(false);
  //     } else {
  //       setText('myMLA Log In');
  //       setIsLoading(false);
  //       setIsEnabled(true);
  //     }
  //   })
  // }

  // React.useEffect(() => {
  //   update();
  // }, []);

  // const isLoggedIn = !!userData?.user?.display_name;

  const text = isLoading ? 'Checking myMLA Login' : (isSignedIn ? `Signed in as ${userData?.user?.display_name}` : 'LOGIN WITH YOUR myMLA ACCOUNT');
  // const isEnabled = !isLoading && !userData?.user?.display_name;

  // return <Button uppercase={false} mode="outlined" //icon="login"
  //   theme={{
  //     roundness: 0,
  //     colors: { primary: '#fff' }
  //   }}
  //   onPress={async () => {
  //     if (isEnabled && !isLoading) {
  //       // setIsLoading(true);
  //       // signOutAsync();
  //       let response = await signInAsync();
  //       if (response?.result == 'SUCCESS') {
  //         // update();
  //       } else {
  //         Alert.alert('Failed to login', 'Something went wrong and you were not signed in to your account.');
  //       }
  //     }
  //   }}
  //   style={{
  //     borderColor: '#fff',
  //   }}
  //   contentStyle={{
  //     padding: 5
  //   }}
  // >
  //   <ActivityIndicator size="small" color={Colors.tintColor} />
  //   {text}
  // </Button>
  return <TouchableRipple
    rippleColor="rgba(255, 255,255, .32)"
    onPress={async () => {
      try {
        if (!isLoading) {
          if (userData?.user?.display_name) {

            if (await confirm('Are you certain you wish to sign out of your account?')) {
              await signOutAsync();
            }
            // // if (confirm("Are you certain you wish to sign out of your account?")) {
            // //   await signOutAsync();
            // // }

            // Alert.alert(
            //   'Notice',
            //   'Are you certain you wish to sign out of your account?',
            //   [
            //     {
            //       text: 'Yes', onPress: () => {
            //         signOutAsync();
            //       }
            //     },
            //     { text: 'No', onPress: () => { }, style: 'cancel' },
            //   ],
            //   { cancelable: false }
            // )

          } else {
            let response = await signInAsync(NotificationService.getToken());
            if (response?.result == 'SUCCESS') {
              // update();
            } else {
              Alert.alert('Failed to login', 'Something went wrong and you were not signed in to your account.');
            }
          }
        }
      } catch (ex) {
        Sentry.Native.captureException(ex);
        Alert.alert('Failed to login', 'An error occurred.');
        Notifications.emit(NotificationTypes.signInAsyncAfter, SignInAsyncResult.FAILED);
      }
    }}
  // style={{ flex: 1, zIndex: 1000 }}
  >
    <View style={styles.button}>
      {isLoading && <View style={styles.loader}>
        <ActivityIndicator size="small" color={'#fff'} />
      </View>}
      {!isLoading && !isSignedIn && <Icon style={{ marginRight: 10, top: 1, position: 'relative' }} name="login" size={20} color="#fff" />}
      {!isLoading && isSignedIn && <Icon style={{ marginRight: 10, top: 1, position: 'relative' }} name="logout" size={20} color="#fff" />}
      <View style={styles.textContainer}>
        <Text style={styles.text}>{text}</Text>
      </View>
    </View>
  </TouchableRipple>;
}

const styles = StyleSheet.create({
  textContainer: {},
  loader: {
    marginRight: 12,
  },
  button: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    flexDirection: 'row',

    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#fff',
    // cursor: 'pointer',
    
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
      default: {},
    }),

  },
  buttonContainer: {
    // backgroundColor: '#fdfdfd',
    // maxWidth: 150,
    //alignSelf: 'center',
  },
  text: {
    fontSize: 15,
    alignSelf: 'flex-start',
    marginTop: 1,
    color: '#fff',
  },
});
